import { render, staticRenderFns } from "./denied.vue?vue&type=template&id=9553a222&scoped=true&"
var script = {}
import style0 from "./denied.vue?vue&type=style&index=0&id=9553a222&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9553a222",
  null
  
)

export default component.exports